import React, { Component } from 'react';
export default class Footer extends Component {
  render() {
    return (
      <footer>
      <div className="row">


        <p style={{color: 'white'}}> &copy; 2023 linalasithiotaki.gr</p>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home" aria-label="home page"><i className="icon-up-open" /></a></div>
      </div>
    </footer>
    );
  }
}