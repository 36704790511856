import React, { Component } from 'react';
import Modal from 'react-modal';
export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;
    let resumeData = this.props.resumeData;
    const customStyles = {
      content: {
        width: '50%', // Set the desired width
        height: '80%', // Set the desired height
        margin: 'auto' // Center the modal horizontally
      }
    };
    return (

      <React.Fragment>

        <header id="home">

          <nav id="nav-wrap">

                  <div>
                  <img className="eu-banner" src="../images/eu2.jpg" alt="EU banner " onClick={this.openModal}/>

                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={this.closeModal}
                contentLabel="Image Modal"
                style={customStyles}
                >
                  <button onClick={this.closeModal} alt="close banner">Close</button>
                <img src="../images/accessbanner.png" alt="Accessibility banner" />
                </Modal>
              </div>
            {/* <img className="eu-banner" src="../images/eu2.jpg" alt="eu banner " /> */}


            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#home" title="Hide navigation" alt="hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
               <li className="current"><a className="smoothscroll" href="#home" alt="home page">Home</a></li>
               <li><a className="smoothscroll" href="#about" alt="about page">About</a></li>
             <li><a className="smoothscroll" href="#resume" alt="resume page">Resume</a></li>
               <li><a className="smoothscroll" href="#portfolio" alt="articles pages">Articles</a></li>
            </ul>
         </nav>

         <div className="row banner">
            <div className="banner-text">
               <h1 className="responsive-headline">I am {resumeData.name}.</h1>
               <h2 style={{fontFamily:'sans-serif'}}>I am a {resumeData.role}.{resumeData.roleDescription}
              </h2>

               <hr/>
            </div>
         </div>

         <p className="scrolldown">
            <a className="smoothscroll" href="#about" aria-label="about page"><i className="icon-down-circle"></i></a>
         </p>

      </header>
      </React.Fragment>
    );
  }
}
