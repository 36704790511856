import React, { Component } from 'react';
import LinkedInLink from './LinkedIn';

export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">


            <div className="nine columns main-col">

                <h2>About Me</h2>
                <img className="profile-pic2"  src="../images/profilepic.jpg" alt="profile of Lina Lasithiotaki" />
               <p style={{color: 'white'}}>
               {
                 resumeData.aboutme
               }
               </p>

               <div className="row">

                  <div className="columns contact-details">

                  <h2>Contact Details</h2>
                  <p className="address" style={{color: 'white'}}>
       						<span>{resumeData.name}</span>
                     <br></br>
       						   <span>
                     {resumeData.address}
                    </span>
                    <br></br>
                         <span>{resumeData.website}</span> <br></br>
                         <span>lina.lasithiotaki@gmail.com</span>

                      </p>
                      <h2>Social Media</h2>
                            <div className="socialMedia"><LinkedInLink></LinkedInLink></div>

                  </div>
               </div>
            </div>
         </div>
      </section>
    );
  }
}