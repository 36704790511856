import React from 'react';
import { LinkedIn } from '@mui/icons-material';

const LinkedInLink = () => {
  return (
    <a href="https://www.linkedin.com/in/linalasithiotaki" target="_blank" rel="noopener noreferrer" alt="LinkedIn" aria-label='Visit LinkedIn'>
      <LinkedIn />
    </a>
  );
};

export default LinkedInLink;