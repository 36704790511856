import React, { Component } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import agriculturalLogo from '../images/agricultural_university_logo.jpeg'
import wageningernLogo from '../images/wageningen-logo.png'
import dioLogo from '../images/dio.jpeg'
import linalogo from '../images/linalogo.png'
import WorkIcon from "@material-ui/icons/Work";
import lebendelogo from "../images/lebendelogo.jpeg"
export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

         <div className="row education">

            <div className="three columns header-col">
               <h1><span>Education</span></h1>
            </div>
                <VerticalTimeline lineColor="#3e497a">
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  iconStyle={{ background: "#3e497a", color: "#fff" }}
                  icon={<SchoolIcon />}
                >
                  <img src={agriculturalLogo} width={100} height={100} alt="Agricultural University of Athens logo" />
                  <h3 className="vertical-timeline-element-title">
                  Agricultural University of Athens
                  </h3>
                  <h4> MEng Agricultural Engineering</h4>
                  <ul style={{ textAlign: 'left', color: 'black' }}>
                    <li><strong>Thesis topic</strong>: Specilization Agronomy, Plant Breeding & Biometry</li>
              </ul>              <hr></hr>
              <div style={{color: 'black'}}>Dates: 2004 - 2010</div>
                      </VerticalTimelineElement>


                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  iconStyle={{ background: "#3e497a", color: "#fff" }}
                  icon={<SchoolIcon />}
                >
                  <img src={wageningernLogo} width={100} height={100} alt="Wageningern University Logo"/>
                  <h3 className="vertical-timeline-element-title">
                    Wageningen University & Research
                  </h3>

                  <h4 className="vertical-timeline-element-subtitle">
                    MSc Organic Agriculture
                  </h4>

                  <ul style={{ textAlign: 'left', color: 'black' }}>
                    <li><strong>Thesis topic</strong>: 'Individual Differences in Response to Minority Descriptive Norms for Organic Food’</li>
                    <li><strong>Minor topic</strong>: ‘Sustainable Rural Development’</li>
                    <li><strong>Internship topic</strong>: 'Nudging consumers towards sustainable meat consumption: The case of Better Leven meat products'</li>
              </ul>              <hr></hr>
                            <div style={{color: 'black'}}>Dates: 2011 - 2013</div>
                      </VerticalTimelineElement>
              </VerticalTimeline>
         </div>
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>Work</span></h1>
            </div>
          <VerticalTimeline lineColor="#3e497a">
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}>
                    <img src={linalogo} width={100} height={100} alt="Lina Lasithiotaki logo"/>
                    <h3 className="vertical-timeline-element-title">
                     Freelance Consultant (Agronomist)
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      Athens, Greece
                    </h4>
                    <ul style={{ textAlign: 'left' , color: 'black'}}>

                <li>Consultancy Services on management and communication on projects related with sustainable practices in agriculture/ seed governance / rural development/ responsible food choices
Independent projects   </li>
                <li>The word of Vegans, Market Trends and Certification (Seminar) </li>
                <li>‘A new impulse for the biodynamic association in Greece: Be part of the change’ (on-going) </li>
                <li> School Garden Project ‘My Healthy School’ (2015)</li>
                <li>Market Research: The Organic Seed Market in Greece (2014)</li>
              </ul>              <hr></hr>
                            <div style={{color: 'black'}}>Dates: 2014 - Present</div>
            </VerticalTimelineElement>

                        <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}>
                    <img src={dioLogo} width={100} height={100} alt="DIO Logo"/>
                    <h3 className="vertical-timeline-element-title">
                      Project Coordinator - Insitute of Ecological Agriuclture DIO
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      Athens, Greece
                    </h4>
                    <ul style={{ textAlign: 'left', color: 'black' }}>
                <li>Content Creation and Social Media Management </li>
                <li>Manage the internal and external communication system </li>
                <li>Coordinating project schedules, resources, and information </li>
                <li>Website update in Joomla</li>
              </ul>              <hr></hr>
                            <div style={{color: 'black'}}>Dates: 2020 - Present</div>
            </VerticalTimelineElement>

                        <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}>
                    <img src={dioLogo} width={100} height={100} alt="DIO Logo"/>
                    <h3 className="vertical-timeline-element-title">
                     International Standards Department - Inspection and Certification Organization of Organic Products, DIO
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      Athens, Greece
                    </h4>
                    <ul style={{ textAlign: 'left' , color: 'black'}}>
                <li>Evaluation of inputs suitable for organic farming  </li>
                <li>Management of applications for certificates for U.S.A & Korea </li>
                <li>Evaluation of product labels for export to U.S.A & Canada </li>
                <li>Communication with clients and provision of information about the requirements of International Standards (Demeter, Naturland, Biocyclic Vegan Standard etc)</li>
              </ul>              <hr></hr>
                            <div style={{color: 'black'}}>Dates: 2020 - Present</div>
            </VerticalTimelineElement>

                      <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: "#e9d35b", color: "#fff" }}
                    icon={<WorkIcon />}>
                    <img src={lebendelogo} width={100} height={100} alt="Lebende Samen Living Seeds Logo"/>
                    <h3 className="vertical-timeline-element-title">
                     Freelance Communication Advisor - Lebende Samen- Living Seeds e.V · Self-employed
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      Athens, Greece
                    </h4>
                    <ul style={{ textAlign: 'left' , color: 'black'}}>
                <li>Project: ‘Organic Food from Organic Seeds’  </li>
                <li>Development of the communication strategy </li>
                <li>Content creation and social media management  </li>
                <li>https://www.lebendesamen.bio/home </li>
              </ul>
              <hr></hr>
                            <div style={{color: 'black'}}>Dates: 2018 - 2019</div>
                  </VerticalTimelineElement>
              </VerticalTimeline>
         </div>


         <div className="row skill">

            <div className="three columns header-col">
               <h1><span>Skills</span></h1>
            </div>

            <div className="nine columns main-col">

               <p style={{color: 'black'}}>
               {resumeData.skillsDescription}
               </p>

   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item) => {
                    return(
                      <li>
                      <span className={`bar-expand ${item.skillshort.toLowerCase()}`}>
                      </span><em>{item.skillname}</em>
                      </li>
                    )
                  })
                }

   					</ul>

   				</div>

   			</div>

         </div>

      </section>
    );
  }
}