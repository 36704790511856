let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Lina Lasithiotaki",
    "role": "Freelance Consultant (Agronomist)",
    "linkedinId":"https://www.linkedin.com/in/linalasithiotaki/",
    "roleDescription": "I specialise in Project Management and coordination, Communication strategies, Organic Argriculture, Event Planning and Management, Public relations and I am a certified Seminar Instructor.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/linalasithiotaki/",
          "className":"fa fa-linkedin"
        },
      ],
    "aboutme":"Energetic, positive, creative, multitasking and organized. I approach a topic from different aspects and that helps me to find practical solutions and prioritize. Sometimes I am perfectionist, sometimes this is helpful, sometimes it is not! Also, I am honest and direct but always kind. ",
    "address":"Greece",
    "website":"linalasithiotaki.gr",
    "skillsDescription":"Agricultural Industry Experience and Skills",
    "skills":[
      {
        "skillname": "Agriculture",
        "skillshort": "agr"
      },
      {
        "skillname": "Sustainable Development",
        "skillshort": "sdev"
      },
      {
        "skillname": "Sustainable Agriculture",
        "skillshort": "sagr"
      },
      {
        "skillname": "Market Research",
        "skillshort": "mres"
      },
      {
        "skillname": "Consumer Behaviour",
        "skillshort": "cbeh"
      },
      {
        "skillname": "Organic Products",
        "skillshort": "oprod"
      }
    ],
    "portfolio":[
      {
        "name":"Challenges in Organic Honey Production",
        "description":"article for DIO for the challenges in organic honey production",
        "url": "https://insteco.gr/nea/arthra/95-prokliseis-sti-biologiki-melissokomia",
        "imageurl": "./images/article1.jpeg",
      },
      {
        "name":"What is biodynamic farming",
        "description":"article for DIO about what is biodynamic farming",
        "url":"https://insteco.gr/nea/arthra/45-%CF%84%CE%B9-%CE%B5%CE%AF%CE%BD%CE%B1%CE%B9-%CE%B2%CE%B9%CE%BF%CE%B4%CF%85%CE%BD%CE%B1%CE%BC%CE%B9%CE%BA%CE%AE-%CE%B3%CE%B5%CF%89%CF%81%CE%B3%CE%AF%CE%B1-%CE%BA%CE%B1%CE%B9-%CF%80%CE%BF%CE%B9%CE%B1-%CE%B5%CE%AF%CE%BD%CE%B1%CE%B9-%CF%80%CF%81%CE%BF%CF%83%CF%84%CE%B9%CE%B8%CE%AD%CE%BC%CE%B5%CE%BD%CE%B7-%CE%B1%CE%BE%CE%AF%CE%B1-%CF%84%CF%89%CE%BD-%CF%80%CF%81%CE%BF%CF%8A%CF%8C%CE%BD%CF%84%CF%89%CE%BD-%CE%BC%CE%B5-%CF%84%CE%BF-%CF%83%CE%AE%CE%BC%CE%B1-%E2%80%98demeter%E2%80%99",
        "imageurl": "./images/article2.jpeg",
      },
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }

  export default resumeData